<script>
import { defineComponent, reactive, ref, computed, createApp, h, onMounted } from "vue";
import TableLite from "vue3-table-lite";
import { index } from "@/use/repositories/devices/index";
import { formatDate } from "@/use/utilities/time/formatDate.js";
import { geolocalize } from "@/use/utilities/devices/geolocalize";
import _head from "lodash/head"

import Brand from "@/components/Brand";
import ButtonBack from "@/components/general/ButtonBack";

export default {
  name: "DigitalLabels",
  components: { TableLite, Brand, ButtonBack },
  setup() {
    const searchTerms = reactive({
      code: "",
      area: "",
      number_text: "",
      battery_life: "",
      mac_address: "",
      customer: "",
      test: "",
      delivery_date: "",
    });
    const columnParams = [
        { label: "Codice", field: "code", width: "3%" },
        { label: "Posizione (ultima)", field: "area", width: "10%" },
        { label: "Posizione calcolata", field: "calculated_area", width: "10%" },
        { label: "Prescrizione", field: "number_text", width: "15%" },
        { label: "Stato batteria", field: "battery_life", width: "10%" },
        { label: "Mac Address", field: "mac_address", width: "10%" },
        { label: "Paziente", field: "customer", width: "15%" },
        { label: "Prova", field: "test", width: "15%" },
        { label: "Data", field: "delivery_date", width: "15%" },
      ];

    const data = ref([]);

    const table = reactive({
      isLoading: false,
      columns: columnParams,
      rows: computed(() => {
        const lowerCaseSearchTerms = Object.fromEntries(
          Object.entries(searchTerms).map(([key, value]) => [key, value.toLowerCase()])
        );

        return data.value.filter(rowValue =>
          Object.keys(lowerCaseSearchTerms).every(key =>
            rowValue[key]?.toLowerCase().includes(lowerCaseSearchTerms[key])
        ));
      }),
      totalRecordCount: computed(() => table.rows.length),
      pageOptions: [
        { value: 15, text: 15 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
    });

    const initTable = ({ el: tableComponent }) => {
      const headerTr = tableComponent.getElementsByClassName("vtl-thead-tr");

      if (! _head(headerTr)) return;

      const cloneTr = _head(headerTr).cloneNode(true);
      const childTh = cloneTr.getElementsByClassName("vtl-thead-th");

      Array.from(childTh).forEach((th) => (th.innerHTML = ""));

      const createSearchInput = (parent, valueKey, placeholder) => {
        createApp(
          defineComponent({
            setup() {
              return () =>
                h("input", {
                  value: searchTerms[valueKey],
                  onInput: (e) => searchTerms[valueKey] = e.target.value,
                  placeholder: `Ricerca ${placeholder}`,
                });
            },
          })
        ).mount(parent);
      };

      columnParams.forEach((column, index) => {
        if (childTh[index]) {
          createSearchInput(childTh[index], column.field, column.label);
        }
      });

      _head(headerTr).after(cloneTr)
    };

    onMounted(() => {
      index('by_digital_labels').then(response => {
        data.value = response.data.map((item) => {
          const { x, y } = item?.extra_data;
          // Extract coordinates from extra_data
          const coordinates = x != null && y != null ? ` | x: ${x}, y: ${y}` : '';

          return {
            code: item?.name,
            area: item?.extra_data?.area ?? 'sconosciuto',
            calculated_area: `${geolocalize(x, y)}${coordinates}`,
            number_text: item?.prescription_tests?.map(test => test?.prescription?.number_text ?? '-').join(', ') ?? '-',
            battery_life: item?.extra_data?.battery ?? 'sconosciuto',
            mac_address: item?.extra_data?.mac ?? 'sconosciuto',
            customer: `${item?.prescription_tests[0]?.prescription?.customer?.first_name ?? '-'} ${item?.prescription_tests[0]?.prescription?.customer?.last_name ?? ''}`,
            test: item?.prescription_tests?.map(test => test?.test?.name ?? '-').join(', ') ?? '-',
            delivery_date: item?.prescription_tests?.map(test => formatDate(test?.test_work_queue?.delivery_date) ?? '-').join(', ') ?? '-',
          };
        });
      })
    })

    return { searchTerms, table, initTable };
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex flex-column justify-content-start align-items-start">
        <brand :width="150" />
        <ButtonBack :label="'indietro'" />
      </div>
      <div class="container mt-5">
        <h4 class="text-center m-2">Di seguito la lista di tutti i Tag registrati</h4>
        <table-lite :is-static-mode="true" :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount" :page-options="table.pageOptions" @VnodeMounted="initTable" />
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #000000;
  background-color: #f3e9ff;
  border-color: #f3e9ff;
}

:deep(.vtl-table td),
:deep(.vtl-table tr) {
  border: none;
  border-bottom: 1px solid #9d9d9d;
  vertical-align: middle;
}

:deep(.vtl-paging-info),
:deep(.vtl-paging-pagination-div),
:deep(.vtl-paging-change-div) {
  padding-top: 30px;
  color: #7e6492;
}

:deep(.vtl-paging-count-label),
:deep(.vtl-paging-page-label) {
  color: #7e6492;
}

:deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>

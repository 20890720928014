<template>
  <!-- COMPONENT MODULATED BY THE PAGE TYPE -->

  <template v-if="goToPrint === false && completed === false">

    <!-- Accordion template -->
    <template v-if="toCollapse && $props.checkoutType === 'bagging' && $props.clinic.test_work_queues.length > 0">
      <div class="accordion w-100" :id="'accordion_' + $props.clinic.clinic_id">
        <div class="accordion-item">

          <h2 class="accordion-header" :id="'heading_' +  + $props.clinic.clinic_id">
            <button class="accordion-button p-2" @click="toggleCaretAccordion()" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse_' +  + $props.clinic.clinic_id" aria-expanded="true" :aria-controls="'collapse_' + $props.clinic.clinic_id">
              <img v-if="showCaretUp" class="mx-2" src="@/assets/icons/caret-up.svg" width="20" alt="caret-up">
              <img v-else class="mx-2" src="@/assets/icons/caret-down.svg" width="20" alt="caret-down">
              <template v-if="showCaretUp === false">
                {{ $props.clinic.clinic_name }}
              </template>
            </button>
          </h2>

          <div :id="'collapse_' + $props.clinic.clinic_id" class="accordion-collapse collapse"
               :aria-labelledby="'heading_' + $props.clinic.clinic_id"
               :data-bs-parent="'#accordion_'  + $props.clinic.clinic_id">

            <div class="accordion-body d-flex allCompletedStyle p-0">

              <!-- Clinic -->
              <div class="d-flex justify-content-center align-items-center p-3  widthCustom">
                {{ $props.clinic.clinic_name }}
              </div>

              <template v-if="allIsPackaged">
                <div class="d-flex align-items-center justify-content-center w-100">
                  Tutte le lavorazioni sono state inscatolate e sono in attesa di essere spedite.
                </div>
              </template>
              <template v-else>
                <!-- Column -->
                <div class="d-flex flex-wrap p-3 border-violet-bottom"
                     :class="{'widthRowPackaging': $props.checkoutType === 'packaging', 'widthRowBagging': $props.checkoutType === 'bagging'}">

                  <template v-for="(row, index) in filterTestWorkQueueByPageType" :key="index">

                    <div class="d-flex justify-content-start align-items-center allCompletedStyle"
                         :style="{'width':'calc(100% /'+getWidthNumberColumnByType($props.checkoutType)+')'}"
                         :class="getCustomClass(row)">
                         <img class="me-2" v-if="row.state_slug === 'bagged'" src="@/assets/icons/check-white.svg" width="25" alt="check-white">
                         <span>{{ row.number_text }}</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center allCompletedStyle text-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
                      {{ row.device?.name ?? '-' }}
                    </div>
                    <div class="d-flex justify-content-center align-items-center allCompletedStyle text-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
                      {{ row.customer_first_name }} {{ row.customer_last_name }}
                    </div>

                    <div class="d-flex justify-content-center align-items-center allCompletedStyle" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)"></div>

                    <div class="d-flex justify-content-center align-items-center allCompletedStyle" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
                      {{ row.delivery_date.format("DD/MM/YYYY HH:mm:ss") }}
                    </div>
                    <div class="d-flex justify-content-center align-items-center allCompletedStyle" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
                      {{ row.appointment_date.format("DD/MM/YYYY HH:mm:ss") }}
                    </div>

                    <template v-if="$props.checkoutType === 'bagging'">
                      <template v-if="row.state_slug === 'bagged' && (! Array.isArray(row.media) || (typeof row.media === 'object' && Object.keys(row.media).length))">
                        <div class="d-flex justify-content-center align-items-center"
                             :style="{'width':`calc(100%/${customWidth})`}"
                             :class="getCustomClass(row)">
                          <div class="d-flex justify-content-center align-items-center allCompletedStyle">
                            <span>{{ getFileNameMedia(row) }}</span>
                            <img class="play-video me-3" @click="showVideo(row)" src="@/assets/icons/play-outline-white.svg" width="25" alt="play-outline-white">
                            <img class="remove-video" @click="removeVideo(row)" src="@/assets/icons/close-circle-white.svg" width="20" alt="close-circle-white">
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="d-flex justify-content-center align-items-center flex-column p-3"
                             :style="{'width':`calc(100%/${customWidth})`}">
                        </div>
                      </template>
                    </template>

                  </template>

                </div>

                <!-- Actions -->
                <template v-if="$props.checkoutType === 'packaging'">
                  <template v-if="showDDTAction">
                    <div class="d-flex justify-content-center align-items-center flex-column p-3 widthCustom border-violet-bottom">
                      <button type="button" class="btn btn-violet text-uppercase" @click="goToPrint = true">
                        <img src="@/assets/icons/printer-white.svg" width="20" alt="print">
                        stampa ddt
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="d-flex justify-content-center align-items-center flex-column p-3 widthCustom border-violet-bottom"></div>
                  </template>
                </template>

              </template>

            </div>

          </div>

        </div>
      </div>
    </template>

    <!-- Normal template -->
    <template v-else-if="$props.clinic.test_work_queues.length > 0">

      <!-- Clinic -->
      <div class="d-flex justify-content-center align-items-center p-3 border-violet-bottom widthCustom">
        {{ $props.clinic.clinic_name }}
        <template v-if="$props.clinic.selected">
          <!-- Switch icon by page -->
          <template v-if="isBaggingPage && inPackaging === false">
            <img class="mx-2" src="@/assets/icons/bag.svg" width="30" alt="bag">
          </template>
          <template v-if="isPackagingPage || inPackaging">
            <img class="mx-2" src="@/assets/icons/box-seam.svg" width="30" alt="bx-seam">
          </template>
        </template>
      </div>

      <!-- Column -->
      <div class="d-flex flex-wrap p-3 border-violet-bottom" :class="{'widthRowPackaging': $props.checkoutType === 'packaging', 'widthRowBagging': $props.checkoutType === 'bagging'}">

        <template v-for="(row, index) in filterTestWorkQueueByPageType" :key="index">

          <!-- Icon state and number text prescription -->
          <div :id="`test_work_queue_${row.id}`" class="d-flex justify-content-start align-items-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
            <template v-if="isPackagingPage">
              <img v-if="row.state_slug === 'bagged'" src="@/assets/icons/upload-video.svg" width="25" alt="upload-video">
              <img v-if="row.state_slug === 'completed'" src="@/assets/icons/clock.svg" width="20" alt="clock">
              <img v-if="row.state_slug === 'packaged'" src="@/assets/icons/check.svg" width="25" alt="check">
            </template>
            <template v-else>
              <template v-if="toCollapse">
                <img v-if="row.state_slug === 'bagged'" src="@/assets/icons/check-white.svg" width="25" alt="check-white">
              </template>
              <template v-else>
                <img v-if="row.state_slug === 'bagged'" src="@/assets/icons/check.svg" width="25" alt="check">
              </template>
              <img v-if="row.state_slug === 'completed'" src="@/assets/icons/upload-video.svg" width="20" alt="upload-video">
            </template>
            <img class="me-1" v-if="row.state_slug === 'pending'" src="@/assets/icons/warning.svg" width="20" alt="warning">
            <a class="pe-auto text-black nav-link" @click.prevent="openConsulting(row.prescription_id)" target="_blank">{{ row.number_text }}</a>
          </div>
          <div class="d-flex justify-content-center align-items-center text-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
            {{ row.device?.name ?? '-' }}
          </div>
          <!-- Customer -->
          <div class="d-flex justify-content-center align-items-center text-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
            {{ row.customer_first_name }} {{ row.customer_last_name }}
          </div>

          <div class="d-flex justify-content-center align-items-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)"></div>

          <!-- Delivery date -->
          <div class="d-flex justify-content-center align-items-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
            {{ row.delivery_date.format("DD/MM/YYYY HH:mm:ss") }}
          </div>

          <!-- Appointment date -->
          <div class="d-flex justify-content-center align-items-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
            {{ row.appointment_date.format("DD/MM/YYYY HH:mm:ss") }}
          </div>

          <!-- Video actions -->
          <template v-if="$props.checkoutType === 'bagging'">
            <template v-if="row.state_slug === 'bagged' && (! Array.isArray(row.media) || (typeof row.media === 'object' && Object.keys(row.media).length))">
              <div class="d-flex justify-content-center align-items-center" :style="{'width':`calc(100%/${customWidth})`}" :class="getCustomClass(row)">
                <div class="d-flex justify-content-center align-items-center">
                  <span>{{ getFileNameMedia(row) }}</span>
                  <img class="play-video me-3" @click="showVideo(row)" src="@/assets/icons/play-outline-green.svg" width="25" alt="play-outline-green">
                  <img class="remove-video" @click="removeVideo(row)" src="@/assets/icons/close-circle.svg" width="20" alt="close-circle">
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-center align-items-center flex-column p-3" :style="{'width':`calc(100%/${customWidth})`}"></div>
            </template>
          </template>

        </template>

      </div>

      <!-- Actions -->
      <template v-if="$props.checkoutType === 'packaging'">
        <template v-if="showDDTAction">
          <div class="d-flex justify-content-center align-items-center flex-column p-3 widthCustom border-violet-bottom">
            <button type="button" class="btn btn-violet text-uppercase" @click="goToPrint = true">
              <img src="@/assets/icons/printer-white.svg" width="20" alt="print">
              stampa ddt
            </button>
          </div>
        </template>
        <template v-else>
          <div class="d-flex justify-content-center align-items-center flex-column p-3 widthCustom border-violet-bottom"></div>
        </template>
      </template>

    </template>

  </template>

  <!-- Print -->
  <template v-if="goToPrint && completed === false">
    <Print
      :allIsCompleted="allIsPackaged"
      :clinic="$props.clinic"
      :finish="completed"
      :focus="setFocus"
      :selectedShippingProvider="selectedShippingProvider"
      :packagedPrescriptions="packagedPrescriptions"
      @continue="readChoose"
      @completed="showMessageAndUpdate"
    />
  </template>
  <!-- Completed message -->
  <template v-if="goToPrint === false && completed">
    <Completed
      :message="'Scatola completata!'"
    />
  </template>
  <!-- Recording modal -->
  <Empty v-if="recordingShowModal"
         :size="'xl'"
         :modal_id="'recording'"
         :options="{ backdrop: 'static', keyboard: false}"
         @hidden="recordingCloseModal">
    <div class="row text-center">
      <h2 class="text-violet">Registrazione Video</h2>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center align-items-center">
        <span class="text-uppercase fw-bold mx-3">clinica: <strong>{{ dataWebcam.clinicName }}</strong></span>
        <span class="text-uppercase fw-bold">prescrizione: <strong>{{ dataWebcam.numberText[0] }}</strong></span>
      </div>
    </div>
    <Webcam
      :test-work-queue-target-id="dataWebcam.testWorkQueueTargetId"
      :test-work-queue-ids="dataWebcam.testWorkQueueIds"
      :number-text="dataWebcam.numberText"
      @setMedia="readMedia"
      @closeModal="recordingCloseModal"
      @resetSelection="removeTemporarySelection"
    />
  </Empty>
  <!-- Video modal -->
  <Empty v-if="showVideoModal"
         :size="'xl'"
         :modal_id="'showing'"
         @hidden="videoCloseModal">
    <div class="d-flex justify-content-center align-items-center">
      {{ dataVideo.numberText }}
      {{ dataVideo.clinicName }}
    </div>
    <video-player :options="{
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: dataVideo.url,
            type: 'video/mp4',
          },
        ],
      }">
    </video-player>
  </Empty>

</template>

<script>
import { reactive, ref, computed, toRaw, watch, onMounted, inject } from 'vue';
import moment from 'moment';
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Modal } from 'bootstrap';
import _ from 'lodash';

import Webcam from "./Webcam.vue";
import VideoPlayer from "./VideoPlayer.vue";

import { remove as removeMedia} from '@/use/repositories/testWorkQueue/media/remove';
import { signedUrl as getMediaSignedUrl } from '@/use/repositories/media/signedUrl';
import { store as storeShippingCode } from '@/use/repositories/shippingCode/store';
import { attachShippingCode } from '@/use/repositories/testWorkQueue/attachShippingCode';
import { storeTransportDocument } from '@/use/repositories/clinic/storeTransportDocument';
import { print } from "@/use/repositories/devices/print";

import { base64ToPdf } from '../../use/utilities/base64ToPdf';

import Completed from "@/components/Completed";
import Empty from "@/components/Modal/Empty";
import Print from "@/components/checkout/Print";

export default {
  name: "ClinicGroup",
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    checkoutType: {
      type: String,
      required: true,
    },
    selectedShippingProvider: {
      type: Object,
      required: true,
    },
    allShippingProviders: {
      type: Array,
      required: false,
    }
  },
  emits: [
    'selectedClinic',
    'uploadTestWorkQueueMedia',
    'updateTesWorkQueueState',
    'remove',
    'setToCompleted',
  ],
  components: {
    Empty,
    Webcam,
    VideoPlayer,
    Print,
    Completed,
  },
  setup(props, {emit}) {
    const { states, devicesType, departments, shippingProviders } = inject("constants");
    const recordingShowModal = ref(false);
    const showVideoModal = ref(false);
    const showingCloseModal = ref(false);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const dataWebcam = reactive({
      testWorkQueueTargetId: null,
      testWorkQueueIds: [],
      numberText: [],
      clinicName: null,
    });
    const dataVideo = reactive({
      clinicName: null,
      numberText: null,
      url: null,
    });
    const goToPrint = ref(false);
    const completed = ref(false);
    const showCaretUp = ref(false);
    const setFocus = ref(false);
    const isBaggingPage = route.params.type === 'bagging';
    const isPackagingPage = route.params.type === 'packaging';
    const customWidth = ref(null);
    const toCollapse = ref(false);


    // Adapt to array logic for zebra
    watch(() => props.clinic.number_text_selected, (array) => {
      if (array.length) {
        scannerBarcodeAction();
      }
    }, { deep: true });

    watch(() => props.clinic.test_work_queues, () => {
      syncClinic();
      atLeastOneInStatePackaged();
    }, { deep: true });

    watch(() => props.checkoutType, () => {
      customWidth.value = getWidthNumberColumnByType(props.checkoutType);
    })

    onMounted(() => {
      customWidth.value = getWidthNumberColumnByType(props.checkoutType);
      syncClinic();
      atLeastOneInStatePackaged();
    });

    const getCustomClass = (row) => {
      return `text-truncate ${row.state_slug}__${route.params.type} ${[states.ISO_FAILED, states.MANAGER_FAILED].includes(row.state_slug) ? 'text-danger alert-danger' : ''}`;
    }

    const scannerBarcodeAction = () => {
      // Recording video only in the bagging action and exists number_text.
      if (isBaggingPage) {
        console.log("VIDEO REC");
        return recordingVideo(props.clinic);
      }

      if (isPackagingPage) {
        for (const id of props.clinic.test_work_queue_id_selected) {
          emit('updateTesWorkQueueState', id, 'packaged');
        }
      }
    }

    const openConsulting = (prescriptionId) => {
      // Resolve the route
      const resolvedRoute = router.resolve({ name: 'ConsultingDashboard', params: { prescription_id: prescriptionId } })

      // Open the resolved route's URL in a new tab
      window.open(resolvedRoute.href, '_blank')
    }

    const resetCaret = () => {
      showCaretUp.value = false;
    }

    const filterTestWorkQueueByPageType = computed(() => {
      if (isBaggingPage) {
        return props.clinic.test_work_queues.filter((item) => {
          return item.state_slug !== states.PACKAGED;
        });
      }

      return props.clinic.test_work_queues;
    });

    const packagedPrescriptions = computed(() => {
      return props.clinic.test_work_queues.filter(twq => twq.state_slug === states.BAGGED);
    })

    const syncClinic = () => {
      toCollapse.value = false;
      let dataFiltered = props.clinic.test_work_queues;

      if (isBaggingPage) {
        dataFiltered = props.clinic.test_work_queues.filter((item) => {
          return item.state_slug !== states.PACKAGED;
        });
      }

      let OnePackaged = props.clinic.test_work_queues.some((el) => el.state_slug === states.PACKAGED);
      //console.log(`IS ONE PACKAGED STATE: ${OnePackaged}`);

      let allInBaggedState = dataFiltered.every((el) => el.state_slug === states.BAGGED);
      let oneBagged = dataFiltered.some((el) => el.state_slug === states.BAGGED);
      //console.log(`IS ALL BAGGED STATE: ${allInBaggedState}`);
      //console.log(`IS ONE BAGGED STATE: ${oneBagged}`);

      if (allInBaggedState) {
        emit('setToCompleted', {id: dataFiltered.clinic_id, value: true});
        toCollapse.value = true;
      } else {
        if (OnePackaged || oneBagged) {
          emit('selectedClinic', {id: props.clinic.clinic_id, value: true});
        } else {
          emit('setToCompleted', {id: dataFiltered.clinic_id, value: false});
          emit('selectedClinic', {id: props.clinic.clinic_id, value: false});

          resetCaret();
        }
      }
    };

    const allIsPackaged = computed (() => {
      return props.clinic.test_work_queues.every((el) => el.state_slug === states.BAGGED)
    });

    const inPackaging = computed (() => {
      return props.clinic.test_work_queues.some((el) => el.state_slug === states.PACKAGED);
    });

    const toggleCaretAccordion = () => {
      showCaretUp.value = ! showCaretUp.value;
    }

    // This method handle the size of table columns
    const getWidthNumberColumnByType = (type) => {
      if (type === 'packaging') {
        return 6;
      }
      return 7;
    }

    const atLeastOneInStatePackaged = () => {
      let atLeastOneInStatePackaged = props.clinic.test_work_queues.some((el) => el.state_slug === states.PACKAGED);
      let atLeastOneInStateBagged = props.clinic.test_work_queues.some((el) => el.state_slug === states.BAGGED);

      if (isPackagingPage && (atLeastOneInStateBagged || atLeastOneInStatePackaged) ) {
        emit('selectedClinic', { id: props.clinic.clinic_id, value: true });
      }
    }

    const showDDTAction = computed(() => {
      return props.clinic.test_work_queues.some((el) => el.state_slug === states.BAGGED);
    });

    const getState = (stateSlug, testWorkQueuePhase, type) => {
      let data = [];

      // Bagging action.
      if (type === 'bagging') {
        switch (stateSlug) {
          case 'completed':
            data.push("DISPONIBILE");
            break;
          case 'bagged':
            data.push("COMPLETATO");
            break;
          default:
            data.push(getTechnicianAndDeparment(testWorkQueuePhase));
        }

      } else {
        // Packaging action.
        switch (stateSlug) {
          case 'bagged':
            data.push("DISPONIBILE");
            break;
          case 'packaged':
            data.push("COMPLETATO");
            break;
          case 'completed':
            data.push("IN ARRIVO");
            break;
          default:
            data.push(getTechnicianAndDeparment(testWorkQueuePhase));
        }
      }

      return data;
    }

    const getTechnicianAndDeparment = (array) => {
      let result = [];
      array.forEach((item) => {
        let departmentName = item.department_name;
        let user = item.technician !== null ? item.technician : '-';

        result.push(`${departmentName} / ${user}`);
      });

      return result;
    }

    const getFileNameMedia = (row) => {
      return _.find(row.media)?.file_name;
    }

    /* ==================== VIDEO =======================*/

    const recordingVideo = async (obj) => {
      // Select the correct target id if there is more than one.
      if (obj.test_work_queue_id_selected.length > 1) {
        const filteredTWQ = props.clinic.test_work_queues.filter(twq =>  obj.test_work_queue_id_selected.includes(twq.id));
        const { id: maxDateTWQId } = filteredTWQ.reduce((latestTWQ, currentTWQ) =>
          moment(currentTWQ.delivery_date).isAfter(moment(latestTWQ.delivery_date)) ? currentTWQ : latestTWQ
        );

        dataWebcam.testWorkQueueTargetId = maxDateTWQId;
      } else {
        dataWebcam.testWorkQueueTargetId = _.head(obj.test_work_queue_id_selected);
      }

      dataWebcam.testWorkQueueIds = obj.test_work_queue_id_selected;
      dataWebcam.clinicName = obj.clinic_name;
      dataWebcam.numberText = obj.number_text_selected;
      recordingShowModal.value = true;
    }

    const videoCloseModal = () => {
      const truck_modal = document.querySelector('#showing');
      if (truck_modal) {
        const modal = Modal.getInstance(truck_modal);
        modal.hide();
        showVideoModal.value = false;
      }
    }

    const showVideo = async(row, clinicName) => {
      let uuid = _.find(row.media).uuid;
      await getMediaSignedUrl(uuid).then((response) => {
        dataVideo.clinicName = clinicName;
        dataVideo.numberText = row.number_text;
        dataVideo.url = response.url;
        showVideoModal.value = true;
      });
    }

    const recordingCloseModal = () => {
      const truck_modal = document.querySelector('#recording');
      if (truck_modal) {
        const modal = Modal.getInstance(truck_modal);
        modal.hide();
        recordingShowModal.value = false;
      }
    }

    const removeVideo = (row) => {
      console.log("REMOVING VIDEO...");
      let uuid = _.find(row.media).uuid;

      // delete video
      removeMedia(row.id, uuid).then((response) => {
        console.log("VIDEO REMOVAL COMPLETED");
        const twqsToUpdate = props.clinic.test_work_queues.filter(item => item.state_slug === states.BAGGED && item.device?.name === row.device?.name);

        emit('uploadTestWorkQueueMedia', response.testWorkQueue);

        if (twqsToUpdate.length) {
          for (const testWorkQueue of twqsToUpdate) emit('updateTesWorkQueueState', testWorkQueue.id, states.COMPLETED);
        }
        // Show message.
        return successMessage();
      });
    }

    const successMessage = () => {
      return toast.success('Cancellazione video avvenuta con successo!', {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    }

    const readMedia = (obj) => {
      emit('uploadTestWorkQueueMedia', obj);
    }

    const readChoose = (value) => {
      return goToPrint.value = value;
    }

    const showMessageAndUpdate = async({ clinic, code, automaticShippingSelectedService, selectedShippingProviderSlug }) => {
      // Extract clinic id
      const clinicId = toRaw(clinic).clinic_id;

      const selectedShippingProvider = props.allShippingProviders.find((item) => item.slug === selectedShippingProviderSlug);

      if ([
        shippingProviders.FEDEX,
        shippingProviders.DHL
      ].includes(selectedShippingProvider.slug) && automaticShippingSelectedService) {
        const { result: { shippingCode, pdf } } = await storeShippingCode({
          action: `by_${selectedShippingProvider.slug}`,
          shipping_provider_id: selectedShippingProvider.id,
          clinic_id: clinicId,
          service_type: automaticShippingSelectedService,
        });

        // Print pdf.
        base64ToPdf(pdf);

        // Extract code
        code = shippingCode;
      }

      const shippingCode = await storeShippingCode({
        action: 'by_all',
        clinic_id: clinicId,
        shipping_provider_id: selectedShippingProvider.id,
        code,
      });

      // Attach shipping code.
      attachShippingCode(
        packagedPrescriptions.value.map(twq => twq.id),
        shippingCode.result.id
      ).then(async (response) => {
        // Show success message.
        goToPrint.value = false;
        completed.value = true;

        // Generate DDT.
        storeTransportDocument(response.shippingCode.id).then(async (response) => {
          // Print pdf
          print({
            device_type_slug: devicesType.PRINTER,
            department_slug: departments.LOGISTICS,
            data: response.data,
          }).then(() => setTimeout(() => {
            // Hide success message.
            completed.value = false;
          }, 3000));

          // Remove clinic obj.
          emit('remove', clinic.clinic_id);
          console.log(`CLINIC: ${clinic.clinic_name} ID:${clinic.clinic_id} SHIPPED COMPLETED`);
        }).catch(() => {
          // TODO: DETACH CALL -> restore state
          // Restore template.
          goToPrint.value = false;
          completed.value = false;
        });
      }).catch(() => {
        // Reset focus on input.
        setFocus.value = true;
      });
    }

    const removeTemporarySelection = () => {
      // Check if exist media.
      let testWorkQueue = props.clinic.test_work_queues.filter((twq) => {
        return twq.number_text === dataWebcam.numberText;
      });

      if (testWorkQueue) {
        emit('selectedClinic', { id: props.clinic.clinic_id, value: false });
      }
    }

    return {
      moment,
      recordingVideo,
      recordingShowModal,
      recordingCloseModal,
      getState,
      dataWebcam,
      showVideo,
      removeVideo,
      readMedia,
      getFileNameMedia,
      showingCloseModal,
      showVideoModal,
      videoCloseModal,
      dataVideo,
      showDDTAction,
      goToPrint,
      readChoose,
      showMessageAndUpdate,
      completed,
      getWidthNumberColumnByType,
      toggleCaretAccordion,
      showCaretUp,
      allIsPackaged,
      removeTemporarySelection,
      isBaggingPage,
      isPackagingPage,
      setFocus,
      inPackaging,
      filterTestWorkQueueByPageType,
      customWidth,
      getCustomClass,
      toCollapse,
      openConsulting,
      packagedPrescriptions,
      scannerBarcodeAction
    }
  }
}
</script>

<style scoped>
.completed__bagging,
.bagged__packaging {
  color: #A58ABA;
}
.pending__packaging,
.pending__bagging {
  color: #E97B86;
}
.bagged__bagging,
.packaged__packaging {
  color: #74C686;
}
.completed__packaging {
  color: #9D9D9D;
}
.play-video:hover,
.remove-video:hover {
  cursor: pointer;
}
.accordion-button:not(.collapsed) {
  color: #FFFFFF;
  background-color: #74C686;
  box-shadow: unset;
}
.allCompletedStyle {
  background-color: #74C686;
  color: #FFFFFF;
}
.widthRowPackaging {
  width: calc(100% - (100% / 7 * 2));
}
.widthRowBagging {
  width: calc(100% - (100% / 7));
}
.widthCustom {
  width: calc(100% / 7);
}
/* Overwrite class bootstrap */
.accordion-button {
  background-color: #74C686 !important;
  color: #FFFFFF !important;
}
.accordion-button::after {
  background-image: unset !important;
}
</style>
